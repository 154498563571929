import { forEach } from 'lodash';
import './bootstrap';
import Swiper, {Pagination} from 'swiper';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
//MODULES
import { getChildsValidateDate } from './modules/ValidateDate';

window.addEventListener("load", function() {
    const loading = document.querySelector('#js-loading');
    if (loading) {
        loading.classList.add('hidden');
    }
    //EJECUCIÓN MODULOS
    getChildsValidateDate();
});

document.addEventListener('DOMContentLoaded', function () {

    //Cambiar estilos del header
    styleHeader();

    //Cambiar de posicion elementos en mobile
    changePositionForm();

    //Swiper
    swiperZoneUser();

    //Modal
    modalTikect();
    modalPsw();
    modalNewPsw();

    //Validacion formulario
    dinamicValid();
    inputError();
    inputError2();

    //Limite de caracteres input
    limitCaracters();

    // limit block code
    limitBlockCode();
    limitBlockCode1();

});

function styleHeader() {
    const headerBody = document.querySelector('.header__body');
    const headerBtn = document.querySelector('.header__btn');
    if(headerBtn) {
        headerBody.classList.remove("flexHeader");
    }
}

//Cambiar de posicion elementos en vista mobile
function changePositionForm() {

    //Ingreso boleto
    const formContainerTicket = document.querySelector('#formContainerTicket');
    const questionTicket = document.querySelector('#questionTicket');

    const widthScreen = screen.width;

    if(widthScreen <= 991){
        formContainerTicket?.appendChild(questionTicket);
    }
}


//Swiper vista Zona usuario
function swiperZoneUser() {
    var swiperZoneUser = new Swiper(".swiperZoneUser", {
        modules: [Pagination],
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
        },
        breakpoints: {
            820: {
              slidesPerView: 4,
              spaceBetween: 10,
            }
          }
    });
}

//Modales

//Modal factura
function modalTikect() {
    const questionTicket = document.querySelector('#questionTicket');
    const popUpTicket = document.querySelector('#popUpTicket');
    const popUpClose = document.querySelector('.popUpTicket__close');

    questionTicket?.addEventListener('click', () => {
        popUpTicket.classList.remove('hidden');
    });

    popUpClose?.addEventListener('click', () => {
        popUpTicket.classList.add('hidden');
    });
}

//Modal Recuperar contraseña
function modalPsw() {
    const questionPsw = document.querySelector('#questionPsw');
    const popUpPassword = document.querySelector('#popUpPassword');
    const popUpClose = document.querySelector('.popUpPassword__close');

    questionPsw?.addEventListener('click', () => {
        popUpPassword.classList.remove('hidden');
    });

    popUpClose?.addEventListener('click', () => {
        popUpPassword.classList.add('hidden');
    });
}

//Modal Nueva contraseña
function modalNewPsw() {
    const popUpNewPassword = document.querySelector('#popUpNewPassword');
    const popUpClose = document.querySelector('.popUpNewPassword__close');


    popUpClose?.addEventListener('click', () => {
        popUpNewPassword.classList.add('hidden');
    });
}


//Validacion formularios
function dinamicValid() {

    let dni = document.querySelector('#document_number');

    //Campos contraseña
    const iconsShowPsw = document.querySelectorAll('.showPsw');
    iconsShowPsw.forEach((icon) => {
        icon.addEventListener('click', e => {
            icon.classList.toggle('hidePsw')
            if(icon.classList.contains('hidePsw')) {
                icon.parentElement.children[1].type = 'text';
            } else {
                icon.parentElement.children[1].type = 'password';
            }
        })
    })

    //Formulario Registro
    const frmRegister = document.querySelector('#frm_register');
    if(frmRegister) {
        const name = document.querySelector('#name');
        const lastName = document.querySelector('#last_name');
        const phone = document.querySelector('#phone');
        const email = document.querySelector('#email');
        const password = document.querySelector('#password');
        const passwordConfirm = document.querySelector('#password-confirm');
        inputEvent(name, 'text');
        inputEvent(lastName, 'text');
        inputEvent(phone, 'number', "Ingrese mínimo 9 números");
        inputEvent(email, 'email', "Email inválido");
        inputEvent(password, 'string', "Ingrese mínimo 8 caracteres");
        inputEvent(passwordConfirm, 'string', "Ingrese mínimo 8 caracteres");

        const selectRegister = document.querySelectorAll('#frm_register select');

        selectRegister.forEach((select) => {
            select.addEventListener('click', e => {
                if(!select.selectedIndex == '' || !select.selectedIndex == null || !select.selectedIndex == 0) {
                    select.parentElement.classList.remove('error')
                    document.querySelector('.errorMessage').style.display = 'none'
                    document.querySelector('.errorMessage').children[0].style.display = 'none'
                }
            })
        })

        const selectTypeDocument = document.querySelector('#frm_register .selectTypeDocument');
        selectTypeDocument.addEventListener('change', (e) => {
            validateSelectTypeDocument(selectTypeDocument)
        })
        inputEvent(dni, 'number', "");

        function validateSelectTypeDocument(selectTypeDocument) {

            //Validación input DNI
            if(selectTypeDocument.selectedIndex == 1) {
                dni.value = '';
                dni.type = 'number';
                dni.setAttribute('maxlength', 8);
            }

            //Validación input CE
            if(selectTypeDocument.selectedIndex == 2) {
                dni.value = '';
                dni.type = 'text';
                dni.setAttribute('maxlength', 12);
                dni.setAttribute('minlength', 4);
            }
        }

    }

    //Formulario Iniciar sesión
    const frmLogin = document.querySelector('#frm_login');
    if(frmLogin) {

        const selectLogin = document.querySelector('#frm_login select');

        selectLogin.addEventListener('click', e => {
                if(!selectLogin.selectedIndex == '' || !selectLogin.selectedIndex == null || !selectLogin.selectedIndex == 0) {
                    selectLogin.parentElement.classList.remove('error')
                }
            })

        selectLogin.addEventListener('change', (e) => {
            validateSelectTypeDocument(selectLogin)
        })

        const password = document.querySelector('#password');
        inputEvent(password, 'string', "Ingrese mínimo 8 caracteres");
        inputEvent(dni, 'number', "");

        function validateSelectTypeDocument(selectTypeDocument) {

            //Validación input DNI
            if(selectTypeDocument.selectedIndex == 1) {
                dni.value = '';
                dni.type = 'number';
                dni.setAttribute('maxlength', 8);
            }

            //Validación input CE
            if(selectTypeDocument.selectedIndex == 2) {
                dni.value = '';
                dni.type = 'text';
                dni.setAttribute('maxlength', 12);
                dni.setAttribute('minlength', 4);
            }
        }
    }

    //Pop up Recuperar contraseña
    const frmLoginPopUp = document.querySelector('#frm_loginPopUp');
    if(frmLoginPopUp) {

        const selectLoginPopUp = document.querySelector('#frm_loginPopUp select');

        selectLoginPopUp.addEventListener('click', e => {
            if(!selectLoginPopUp.selectedIndex == '' || !selectLoginPopUp.selectedIndex == null || !selectLoginPopUp.selectedIndex == 0) {
                selectLoginPopUp.parentElement.classList.remove('error')
            }
        })

        selectLoginPopUp.addEventListener('change', (e) => {
            validateSelectTypeDocument(selectLoginPopUp)
        })

        const dniPopUp = document.querySelector('#document_number-psw');
        inputEventPopUp(dniPopUp, 'number', "");

        function validateSelectTypeDocument(selectTypeDocument) {

            //Validación input DNI
            if(selectTypeDocument.selectedIndex == 1) {
                dniPopUp.value = '';
                dniPopUp.type = 'number';
                dniPopUp.setAttribute('maxlength', 8);
            }

            //Validación input CE
            if(selectTypeDocument.selectedIndex == 2) {
                dniPopUp.value = '';
                dniPopUp.type = 'text';
                dniPopUp.setAttribute('maxlength', 12);
                dniPopUp.setAttribute('minlength', 4);
            }
        }
    }

    //Pop up Nueva contraseña
    const frmLoginNewPsw = document.querySelector('#frm_loginNewPsw');
    if(frmLoginNewPsw) {
        const newPassword = document.querySelector('#newPassword');
        const newPasswordConfirm = document.querySelector('#newPassword-confirm');
        inputEventPopUp(newPassword, 'string', "Ingrese mínimo 8 caracteres");
        inputEventPopUp(newPasswordConfirm, 'string', "Ingrese mínimo 8 caracteres");
    }

    //Formulario Ticket
    const selectTicket = document.querySelectorAll('#frm_ticket select');

    selectTicket.forEach((select) => {
        select.addEventListener('click', e => {
            if(!select.selectedIndex == '' || !select.selectedIndex == null || !select.selectedIndex == 0) {
                select.parentElement.classList.remove('error')
                select.parentElement.children[2].classList.add('hidden')
            }
        })
    })
}

function inputEvent(input, type = 'text', msg = "campo inválido", typeDocument = '') {
    const btnRegister = document.querySelector('.btnError');
    const blockError = document.createElement('SPAN')
    
    input.addEventListener('input', e => {
        
        //Validación campos tipo text
        //let expR = /\d/;
        let expR = /[0-9]|[`!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?~]/;
        if(type === 'text' && expR.test(e.target.value)) {
            blockError.classList.add('errorValidation')
            blockError.textContent = 'El campo no puede tener números y/o caracteres especiales'
            input.parentElement.classList.add('error')
            input.parentElement.appendChild(blockError)
            btnRegister?.classList.add('disabled')
            return;
        } else {
            blockError?.remove();
        }

        //Validación campos tipo email
        const emailValue = e.target.value;
        const expReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!expReg.test(emailValue) && type === 'email' && input.value.length >0) {
            blockError.classList.add('errorValidation');
            blockError.textContent = msg;
            input.parentElement.classList.add('error');
            input.parentElement.appendChild(blockError);
    
            // if (blockError.previousElementSibling.classList.contains('invalid-feedback')) {
            //     var elementDelete = blockError.parentElement.children[2];
            //     console.log('elementDelete: ',elementDelete)
            //     blockError.parentElement.removeChild(elementDelete);
            // }
    
            btnRegister.classList.add('disabled');
            return;
        } else {
            blockError?.remove();
        }

        //Validación campos número
        if(type === 'number') {
            //Campo CE
            if(input.type == 'text') {
                blockError?.remove();
                let expS = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                if(expS.test(e.target.value)){
                    blockError.classList.add('errorValidation')
                    blockError.textContent = 'El campo no puede tener caracteres especiales'
                    input.parentElement.classList.add('error')
                    input.parentElement.appendChild(blockError)
                    btnRegister.classList.add('disabled')
                    return;
                }
                if(input.value.length < input.minLength && input.value.length >0){
                    blockError?.remove();
                    blockError.classList.add('errorValidation');
                    blockError.textContent = "Ingrese mínimo " + input.minLength + " números";
                    input.parentElement.classList.add('error');
                    input.parentElement.appendChild(blockError);
                    btnRegister.classList.add('disabled');
                    return;
                }
                btnRegister.classList.remove('disabled');
                return;
            }
            //Campo teléfono
            if(input.minLength > 0 && input.maxLength > 0) {
                if(input.value.length < input.minLength && input.value.length >0){
                    blockError?.remove();
                    blockError.classList.add('errorValidation');
                    blockError.textContent = "Ingrese " + input.maxLength + " números";
                    input.parentElement.classList.add('error');
                    input.parentElement.appendChild(blockError);
                    btnRegister.classList.add('disabled');
                    return;
                }
                // if(input.value.length > input.maxLength && input.value.length >0){
                //     blockError?.remove();
                //     blockError.classList.add('errorValidation');
                //     blockError.textContent = "Ingrese máximo" + input.maxLength + " números";
                //     input.parentElement.classList.add('error');
                //     input.parentElement.appendChild(blockError);
                //     btnRegister.classList.add('disabled');
                //     return;
                // }
                btnRegister.classList.remove('disabled');
                return;
            }
            //Validacion dni
            if(input.value.length < input.maxLength && input.value.length > 0) {
                blockError?.remove();
                blockError.classList.add('errorValidation');
                blockError.textContent = "Ingrese " + input.maxLength + " números";
                input.parentElement.classList.add('error');
                input.parentElement.appendChild(blockError);
                btnRegister.classList.add('disabled');
                return;
            }
        } else {
            blockError?.remove();
        }
        //Validación campo contraseña
        if(type === 'string' && input.value.length <input.minLength && input.value.length >0 && typeDocument === '') {
            blockError?.remove();
            blockError.classList.add('errorValidation')
            blockError.classList.add('errorValidationShowPsw')
            blockError.textContent = 'Ingrese mínimo ' + input.minLength + ' caracteres';
            input.parentElement.classList.add('error')
            input.parentElement.appendChild(blockError)
            btnRegister.classList.add('disabled')
            return;
        } else {
            blockError?.remove();
        }

        // if (input.parentElement.children[2] !== undefined || input.parentElement.children[2] !== null && input.parentElement.children[2].classList.contains('errorValidation')) {
        //     const blockError = document.querySelectorAll('.errorValidation')
        //     blockError.forEach((block) => {
        //         block?.remove();
        //     })
        //     // input.parentElement?.removeChild(blockError);
        // }
    
        input.parentElement.classList.remove('error');
        input.classList.contains('error') && input.classList.remove('error');
        const errorValidation = document.querySelector('.errorValidation')
        if(!errorValidation){
            btnRegister.classList.remove('disabled');
        }
    });
}

function inputEventPopUp(input, type = 'text', msg = "campo inválido", typeDocument = '') {
    const btnLoginPopUp = document.querySelector('.btnLoginPopUp');
    const btnLoginNewPsw = document.querySelector('.btnLoginNewPsw');
    const blockError = document.createElement('SPAN')
    
    input.addEventListener('input', e => {

        //Validación campos
        if(type === 'number') {
            //Campo CE
            if(input.type == 'text') {
                blockError?.remove();
                let expS = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                if(expS.test(e.target.value)){
                    blockError.classList.add('errorValidation')
                    blockError.textContent = 'El campo no puede tener caracteres especiales'
                    input.parentElement.classList.add('error-psw')
                    input.parentElement.appendChild(blockError)
                    btnLoginPopUp.classList.add('disabled')
                    return;
                }
                if(input.value.length < input.minLength && input.value.length >0){
                    blockError?.remove();
                    blockError.classList.add('errorValidation');
                    blockError.textContent = "Ingrese mínimo " + input.minLength + " números";
                    input.parentElement.classList.add('error-psw');
                    input.parentElement.appendChild(blockError);
                    btnLoginPopUp.classList.add('disabled');
                    return;
                }
                btnLoginPopUp.classList.remove('disabled');
                return;
            } else {
                blockError?.remove();
            }
            //Validacion dni
            if(input.value.length < input.maxLength && input.value.length > 0) {
                blockError?.remove();
                blockError.classList.add('errorValidation');
                blockError.textContent = "Ingrese " + input.maxLength + " números";
                input.parentElement.classList.add('error-psw');
                input.parentElement.appendChild(blockError);
                btnLoginPopUp.classList.add('disabled');
                return;
            }
        } else {
            blockError?.remove();
        }

        //Validación campo contraseña
        if(type === 'string' && input.value.length <input.minLength && input.value.length >0 && typeDocument === '') {
            blockError?.remove();
            blockError.classList.add('errorValidation')
            blockError.classList.add('errorValidationShowPsw')
            blockError.textContent = 'Ingrese mínimo ' + input.minLength + ' caracteres';
            input.parentElement.classList.add('error-psw')
            input.parentElement.appendChild(blockError)
            btnLoginPopUp?.classList.add('disabled')
            btnLoginNewPsw?.classList.add('disabled')
            return;
        } else {
            blockError?.remove();
        }

        // if (input.parentElement.children[2] !== undefined || input.parentElement.children[2] !== null && input.parentElement.children[2].classList.contains('errorValidation')) {
        //     const blockError = document.querySelectorAll('.errorValidation')
        //     blockError.forEach((block) => {
        //         block?.remove();
        //     })
        //     // input.parentElement?.removeChild(blockError);
        // }
    
        input.parentElement.classList.remove('error-psw');
        input.classList.contains('error-psw') && input.classList.remove('error-psw');
        const errorValidation = document.querySelector('.errorValidation')
        if(!errorValidation){
            btnLoginPopUp?.classList.remove('disabled');
            btnLoginNewPsw?.classList.remove('disabled');
        }
    });
}

function limitCaracters() {
    const limitName = document.querySelector('#name');
    limitName?.addEventListener('input', limit => {
        if (limitName.hasAttribute('maxlength')) {
            limitName.value = limitName.value.slice(0, limitName.getAttribute('maxlength'));
        }
    })

    const limitLastName = document.querySelector('#lastName');
    limitLastName?.addEventListener('input', limit => {
        if (limitLastName.hasAttribute('maxlength')) {
            limitLastName.value = limitLastName.value.slice(0, limitLastName.getAttribute('maxlength'));
        }
    })

    const limitPhone = document.querySelector('#phone');
    limitPhone?.addEventListener('input', limit => {
        if (limitPhone.hasAttribute('maxlength')) {
            limitPhone.value = limitPhone.value.slice(0, limitPhone.getAttribute('maxlength'));
        }
    })

    const limitEmail = document.querySelector('#email');
    limitEmail?.addEventListener('input', limit => {
        if (limitEmail.hasAttribute('maxlength')) {
            limitEmail.value = limitEmail.value.slice(0, limitEmail.getAttribute('maxlength'));
        }
    })

    const limitDniPopUp = document.querySelector('#document_number-psw');
    limitDniPopUp?.addEventListener('input', limit => {
        if (limitDniPopUp.hasAttribute('maxlength')) {
            limitDniPopUp.value = limitDniPopUp.value.slice(0, limitDniPopUp.getAttribute('maxlength'));
        }
    })

    const limitDni = document.querySelector('#document_number');
    limitDni?.addEventListener('input', limit => {
        if (limitDni.hasAttribute('maxlength')) {
            limitDni.value = limitDni.value.slice(0, limitDni.getAttribute('maxlength'));
        }
    })

    const password = document.querySelector('#password');
    password?.addEventListener('input', limit => {
        if (password.hasAttribute('maxlength')) {
            password.value = password.value.slice(0, password.getAttribute('maxlength'));
        }
    })

    const passwordConfirm = document.querySelector('#password-confirm');
    passwordConfirm?.addEventListener('input', limit => {
        if (passwordConfirm.hasAttribute('maxlength')) {
            passwordConfirm.value = passwordConfirm.value.slice(0, passwordConfirm.getAttribute('maxlength'));
        }
    })
}

function inputError() {
    const inavlidFeedBack = document.querySelectorAll('.invalid-feedback');
    if(inavlidFeedBack) {
        [...inavlidFeedBack].map( e => {
            if(e.parentElement.classList.contains('form__container .error')) {
                e.parentElement.classList.add('error')
                e.previousElementSibling.classList.add('error');
            } if(e.parentElement.classList.contains('dateInfo')) {
                console.log('dateInfo');
                const arrDate = e.parentElement.children;
                // console.log(e.parentElement.children);
                [...arrDate].map( x => {
                    if(x.classList.contains('form__container')) {
                        x.classList.add('error')
                    }
                })
            }
        })
    }

    //Pop up Recuperar contraseña
    const inavlidFeedBackPopUp = document.querySelectorAll('.invalid-feedback-popUp');
    if(inavlidFeedBackPopUp) {
        [...inavlidFeedBackPopUp].map( e => {
            if(e.parentElement.classList.contains('form__container .error-psw')) {
                e.parentElement.classList.add('error-psw')
                e.previousElementSibling.classList.add('error-psw');
            }
        })
    }

    //Campo contraseña
    const inavlidFeedBackPsw = document.querySelectorAll('.invalid-feedback-psw');
    if(inavlidFeedBackPsw) {
        [...inavlidFeedBackPsw].map( e => {
            if(e.parentElement.classList.contains('form__container .error')) {
                e.parentElement.classList.add('error')
                e.previousElementSibling.classList.add('error');
            }
        })
    }

    //Pop up Nueva contraseña
    const inavlidFeedBackNewPsw = document.querySelectorAll('.invalid-feedback-newPsw');
    if(inavlidFeedBackNewPsw) {
        [...inavlidFeedBackNewPsw].map( e => {
            if(e.parentElement.classList.contains('form__container .error-psw')) {
                e.parentElement.classList.add('error-psw')
                e.previousElementSibling.classList.add('error-psw');
            }
        })
    }
}
function inputError2() {
    const inavlidFeedBack = document.querySelectorAll('.invalid-feedback');
    if(inavlidFeedBack) {
        [...inavlidFeedBack].map( e => {
            if(e.parentElement.classList.contains('form__container')) { 
                e.previousElementSibling.addEventListener('input', x => {
                    if(x.target.value !== "") {
                        e.parentElement.classList.contains('error') == true ? e.parentElement.classList.remove('error') : '';
                        e.previousElementSibling.classList.remove('error');
                        e.parentElement.children[2].classList.add('hidden');
                        return;
                    } else {
                        e.parentElement.classList.add('error')
                        e.previousElementSibling.classList.add('error');
                        e.parentElement.children[2].classList.remove('hidden');
                    }

                })
            }
        })
    }

    //Pop up Recuperar contraseña
    const inavlidFeedBackPopUp = document.querySelectorAll('.invalid-feedback-popUp');
    if(inavlidFeedBackPopUp) {
        [...inavlidFeedBackPopUp].map( e => {
            if(e.parentElement.classList.contains('form__container')) { 
                e.previousElementSibling.addEventListener('input', x => {
                    if(x.target.value !== "") {
                        e.parentElement.classList.contains('error-psw') == true ? e.parentElement.classList.remove('error-psw') : '';
                        e.previousElementSibling.classList.remove('error-psw');
                        e.parentElement.children[2].classList.add('hidden');
                        return;
                    } else {
                        e.parentElement.classList.add('error-psw')
                        e.previousElementSibling.classList.add('error-psw');
                        e.parentElement.children[2].classList.remove('hidden');
                    }

                })
            }
        })
    }

    //Campo contraseña
    const inavlidFeedBackPsw = document.querySelectorAll('.invalid-feedback-psw');
    if(inavlidFeedBackPsw) {
        [...inavlidFeedBackPsw].map( e => {
            if(e.parentElement.classList.contains('form__container')) {
                e.parentElement.children[1].addEventListener('input', x => {
                    if(x.target.value !== "") {
                        e.parentElement.classList.contains('error') == true ? e.parentElement.classList.remove('error') : '';
                        e.previousElementSibling.classList.remove('error');
                        e.parentElement.children[3].classList.add('hidden');
                        return;
                    } else {
                        e.parentElement.classList.add('error')
                        e.previousElementSibling.classList.add('error');
                        e.parentElement.children[3].classList.remove('hidden');
                    }

                })
            }
        })
    }

     //Pop up Nueva contraseña
     const inavlidFeedBackNewPsw = document.querySelectorAll('.invalid-feedback-newPsw');
     if(inavlidFeedBackNewPsw) {
         [...inavlidFeedBackNewPsw].map( e => {
             if(e.parentElement.classList.contains('form__container')) {
                 e.parentElement.children[1].addEventListener('input', x => {
                     if(x.target.value !== "") {
                         e.parentElement.classList.contains('error-psw') == true ? e.parentElement.classList.remove('error-psw') : '';
                         e.previousElementSibling.classList.remove('error-psw');
                         e.parentElement.children[3].classList.add('hidden');
                         return;
                     } else {
                         e.parentElement.classList.add('error-psw')
                         e.previousElementSibling.classList.add('error-psw');
                         e.parentElement.children[3].classList.remove('hidden');
                     }
 
                 })
             }
         })
     }
}

function limitBlockCode() {
    const arrBlockCode = document.querySelectorAll('.enterCode__block .inputField');
    [...arrBlockCode].map( blockCode => {
        blockCode.addEventListener('keyup', e => {
            blockCode.value = blockCode.value.slice(0, 1);
            const getParent = blockCode.parentNode.nextElementSibling;
            const getParentPrev = blockCode.parentNode.previousSibling;
            const getOtherParent = blockCode.closest('.enterCode__block');
            if(e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'Delete' && blockCode.value === '') {
                blockCode.parentElement.previousElementSibling?.children[0]?.focus();
                //console.log(getParentPrev)
                if(blockCode.parentElement.previousElementSibling?.children[0] === undefined && getOtherParent.previousElementSibling?.textContent === '-'){
                    arrBlockCode[3].focus();
                }
            } else {
                if(e.key !== 'Tab'){
                    blockCode.parentElement.nextElementSibling?.children[0].focus();
                    if(blockCode.parentElement.nextElementSibling?.children[0] === undefined && getParent === null && getOtherParent.nextElementSibling?.textContent === '-'){
                        arrBlockCode[4].focus();
                    }
                }
            }
        })
    })
}

function limitBlockCode1() {
    const arrBlockCode1 = document.querySelectorAll('.enterCode__block .inputField1');
    [...arrBlockCode1].map( blockCode => {
        blockCode.addEventListener('keyup', e => {
            blockCode.value = blockCode.value.slice(0, 1);
            const getParent = blockCode.parentNode.nextElementSibling;
            const getParentPrev = blockCode.parentNode.previousSibling;
            const getOtherParent = blockCode.closest('.enterCode__block');
            if(e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'Delete' && blockCode.value === '') {
                blockCode.parentElement.previousElementSibling?.children[0]?.focus();
                //console.log(getParentPrev)
                if(blockCode.parentElement.previousElementSibling?.children[0] === undefined && getOtherParent.previousElementSibling?.textContent === '-'){
                    arrBlockCode1[4].focus();
                }
            } else {
                if(e.key !== 'Tab'){
                    blockCode.parentElement.nextElementSibling?.children[0].focus();
                    if(blockCode.parentElement.nextElementSibling?.children[0] === undefined && getParent === null && getOtherParent.nextElementSibling?.textContent === '-'){
                        arrBlockCode1[5].focus();
                    }
                }
            }
        })
    })
}