const ValidateDate = (()=>{

    //---------------- 
    //VALIDAR SI LA FECHA EXISTE
    // ---------------  
    const ValidateDate = ()=>{
        const reUseValidateDate = (dateString)=> {
            let parts = dateString.split("-");
            let day = parseInt(parts[2], 10);
            let month = parseInt(parts[1], 10) - 1; // Los meses en JavaScript van de 0 a 11
            let year = parseInt(parts[0], 10);
            let createDate = new Date(year, month, day);
            //VALIDAMOS QUE TODOS LOS PARAMETROS COINCIDAN
            return (
                createDate.getDate() === day &&
                createDate.getMonth() === month &&
                createDate.getFullYear() === year
            );
        }
        //TRAEMOS LOS SELECT DE FECHA
        const getDay = document.querySelector('#day');
        const getMonth = document.querySelector('#month');
        const getYear = document.querySelector('#year');
        const getAllSelects = document.querySelectorAll('.dateInfo select');
        //console.log(getAllSelects);
        let completeDate;
        //CREAMOS UNA FUNCIÓN PARA REUTILIZAR EL EVENTO CHANGE Y VALIDAR EL AÑO COMPLETO
        const ReUseEvent = (select)=>{
            select.addEventListener('change', ()=>{
                completeDate = `${getYear.value}-${getMonth.value}-${getDay.value}`;
                if(getDay.value != 'DD' && getMonth.value != 'MM' && getYear.value != 'AAAA'){
                    const getReturn = reUseValidateDate(completeDate);
                    if(getReturn != true){
                        getAllSelects.forEach((data)=>{
                            data.value = data.firstElementChild.textContent; 
                            data.closest('.form__container').classList.add('error');
                        });
                    }
                }
            });
        }
        ReUseEvent(getDay);
        ReUseEvent(getMonth);
        ReUseEvent(getYear);
    }



    return {
        childsValidateDate : function(){
            try { ValidateDate(); } catch (error) {  }
        }
    }


})();



const getChildsValidateDate = ()=>{
    ValidateDate.childsValidateDate();
}


export { getChildsValidateDate }